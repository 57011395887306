type GridContainerProps = {
  children: React.ReactNode;
  className?: string; // Optional className prop
};

const GridContainer: React.FC<GridContainerProps> = ({ children, className }) => {
  return (
    <div className={`r-grid-container ${className ? className : ""}`}>
      {children}
    </div>
  );
};

export default GridContainer;