import styled from "styled-components";
import Cart from "../../store/cart/model";
import { formatTotal } from "../../utils/cart";
import Text from "../Text";
import CartProduct from "../../store/cart-product/model";

interface LedgerReceiptProps {
  activeCart?: Cart;
  activeCartProducts?: CartProduct[];
  isProcessing: boolean;
  activeCoupon?: any;
  appliedPromotion?: any;
  collapsed?: boolean;
}

const ReceiptList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    justify-content: space-between;
    margin: 0 0 var(--spacing-0_5);
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &.promo {
      * {
        color: var(--success-green);
      }
    }

    &.total {
      margin-bottom: var(--spacing-1);
      padding-bottom: var(--spacing-1);
      border-bottom: 1px solid var(--cool-40);
    }
  }
`;

const ReceiptListLabel = styled.span.attrs({
  className: "typography-label3",
})``;

const ReceiptListValue = styled.span.attrs({
  className: "typography-body3 font-circular",
})`
  text-align: right;

  & > span {
    color: var(--indigo-blue-60);
  }
`;

const ReceiptListRecurringCharges = styled.span.attrs({
  className: "typography-body3 font-circular",
})`
  color: var(--indigo-blue-60);
`;

const FutureRecurringCharges = ({
  activeCart,
  isProcessing,
  collapsed,
}: {
  activeCart: Cart;
  isProcessing: boolean;
  collapsed?: boolean;
}) => {
  const { recurringBillingDetails } = activeCart;

  if (
    !recurringBillingDetails ||
    Object.keys(recurringBillingDetails).length === 0
  ) {
    return null;
  }

  const billingKeys = Object.keys(recurringBillingDetails);
  const hasMultipleCharges = billingKeys.length > 1;

  const getRecurringChargeLabel = (key: string) => {
    switch (key) {
      case "30":
        return "Monthly";
      case "90":
        return "Every 3 months";
      default:
        return `Every ${key} days`; // For any other future keys
    }
  };

  return (
    <>
      {!collapsed && (
        <li>
          <ReceiptListRecurringCharges>
            {hasMultipleCharges
              ? "This order has multiple recurring charges."
              : "This order has a recurring charge."}
          </ReceiptListRecurringCharges>
        </li>
      )}
      {billingKeys.map((key) => {
        const billingDetail = recurringBillingDetails[key];
        if (!billingDetail) {
          return null;
        }

        return (
          <li key={key}>
            <ReceiptListRecurringCharges>
              {getRecurringChargeLabel(key)}
            </ReceiptListRecurringCharges>
            <ReceiptListRecurringCharges>
              {formatTotal(billingDetail.amount, false, isProcessing, true)}
            </ReceiptListRecurringCharges>
          </li>
        );
      })}
    </>
  );
};

const LedgerReceipt = ({
  activeCart,
  activeCoupon,
  activeCartProducts,
  appliedPromotion,
  isProcessing,
  collapsed,
}: LedgerReceiptProps) => {
  if (!activeCart) return null;

  const hasFutureRecurringCharges = !!activeCart.recurringBillingDetails;
  
  if (collapsed) {
    return (
      <ReceiptList>
        <li>
          <ReceiptListLabel>Today's Total</ReceiptListLabel>
          <ReceiptListValue>
            {formatTotal(activeCart.total, false, isProcessing, true)}
          </ReceiptListValue>
        </li>
        {hasFutureRecurringCharges && (
          <FutureRecurringCharges
            activeCart={activeCart}
            isProcessing={isProcessing}
            collapsed={collapsed}
          />
        )}
      </ReceiptList>
    );
  }

  return (
    <ReceiptList>
      {activeCart?.giftCards?.map((giftCard) => {
        return (
          <li className="promo">
            <ReceiptListLabel>Gift Card</ReceiptListLabel>
            <ReceiptListValue>
              {formatTotal(giftCard?.amount, true, isProcessing)}
            </ReceiptListValue>
          </li>
        );
      })}
      {activeCoupon && (
        <li className="promo">
          <ReceiptListLabel>Promo: {activeCoupon.code}</ReceiptListLabel>
          <ReceiptListValue>
            {formatTotal(activeCart.discountAmount, true, isProcessing)}
          </ReceiptListValue>
        </li>
      )}
      {appliedPromotion &&
        activeCartProducts &&
        activeCartProducts.length > 0 && (
          <li className="promo">
            <ReceiptListLabel>{appliedPromotion.displayName}</ReceiptListLabel>
            <ReceiptListValue>
              {formatTotal(activeCart.discountAmount, true, isProcessing)}
            </ReceiptListValue>
          </li>
        )}
      {!!activeCart.productOfferDiscountAmount && (
        <li className="promo">
          <ReceiptListLabel>Bundle Discount</ReceiptListLabel>
          <ReceiptListValue>
            {formatTotal(
              activeCart.productOfferDiscountAmount,
              true,
              isProcessing,
            )}
          </ReceiptListValue>
        </li>
      )}
      <li>
        <ReceiptListLabel>Subtotal</ReceiptListLabel>
        <ReceiptListValue>
          {formatTotal(activeCart.subtotal, false, isProcessing, true)}
        </ReceiptListValue>
      </li>
      <li>
        <ReceiptListLabel>Shipping</ReceiptListLabel>
        <ReceiptListValue>
          {activeCart?.shippingAmount ? (
            formatTotal(activeCart.shippingAmount, false, isProcessing, true)
          ) : (
            <Text id="cart.receipt.free" defaultMessage="Free" />
          )}
        </ReceiptListValue>
      </li>
      <li>
        <ReceiptListLabel>Tax</ReceiptListLabel>
        <ReceiptListValue>
          {!!activeCart?.shippingAddress?.state ? (
            formatTotal(activeCart.totalTax, false, isProcessing, true)
          ) : (
            <span>Not yet calculated</span>
          )}
        </ReceiptListValue>
      </li>
      <li className="total">
        <ReceiptListLabel>Today's Total</ReceiptListLabel>
        <ReceiptListValue>
          {formatTotal(activeCart.total, false, isProcessing, true)}
        </ReceiptListValue>
      </li>
      {hasFutureRecurringCharges && (
        <FutureRecurringCharges
          activeCart={activeCart}
          isProcessing={isProcessing}
          collapsed={collapsed}
        />
      )}
    </ReceiptList>
  );
};

export default LedgerReceipt;
