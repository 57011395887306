import styled from "styled-components";
import { centsToCurrencyString } from "../../utils/centsToCurrencyString";
import { RecurringBillingDetails } from "../../store/cart/model";

const Container = styled.div`
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-3);
`;

const Text = styled.p`
  color: var(--Solid-Primary-IndigoBlue-60, #62719f);
  a {
    color: var(--Solid-Primary-IndigoBlue-60, #62719f);
    text-decoration: underline;
  }
`;
const dateOffset = process.env.GATSBY_FIRST_BILL_DATE_OFFSET;

const CheckoutTerms = ({
  recurringBillingDetails = {},
}: {
  recurringBillingDetails: RecurringBillingDetails;
}) => {
  const uniqueSubscriptionTypes = Object.keys(recurringBillingDetails);

  const dateOffsetText = `Your second shipment will be charged and shipped ${dateOffset} days early
      to prevent any gaps in your Ritual. `;

  const billingCadenceAndAmountText = uniqueSubscriptionTypes
    .map(
      (ust) =>
        `${centsToCurrencyString(
          recurringBillingDetails[ust]!.amount,
        )} every ${ust} days`,
    )
    .join(` and `);

  return (
    <Container className="typography-caption" data-testid="checkout-terms">
      <Text>
        By clicking 'Purchase', you confirm that you have read, understand, and
        agree to the <a href="/terms-of-service" target="_blank">Terms of Service</a> and{" "}
        <a href="/privacy-policy" target="_blank">Privacy Policy</a>.{" "}
        {uniqueSubscriptionTypes.length === 1 && (
          <span>
            Some of the items you are purchasing will automatically renew as a
            subscription and will be charged {billingCadenceAndAmountText} until
            you cancel. {dateOffset && dateOffsetText}
          </span>
        )}
        {uniqueSubscriptionTypes.length > 1 && (
          <span>
            Some of the items you are purchasing will automatically renew as
            separately managed subscriptions, billing{" "}
            {billingCadenceAndAmountText} until you cancel.
            {dateOffset && dateOffsetText}
          </span>
        )}
        <span>
          You can cancel anytime by visiting your account page on our website.
        </span>
      </Text>
    </Container>
  );
};

export default CheckoutTerms;
