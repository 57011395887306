import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const StickyWrapper = styled.div`
  transition: opacity 400ms ease-out;

  &.sticky {
    position: fixed;
    top: ${(p) => {
      const headerHeight = 0;
      return p.offsetFromBanner
        ? p.offsetFromBanner + headerHeight
        : headerHeight;
    }}px;
    width: 100%;
    z-index: 101;
    opacity: 1;
  }

  background: white;
`;

const Wrapper = styled.div`
  min-height: ${(props) => props.height}px;
`;

const Placeholder = styled.div`
  height: 1px;
`;

const StickyBar = ({ children }) => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);
  const wrapperRef = useRef(null);
  const placeholderRef = useRef(null);
  const offsetFromBanner = useSelector((state) => state.navigation.offset);

  useEffect(() => {
    const observerCallback = (entries) => {
      const [entry] = entries;
      if (!entry.isIntersecting && entry.boundingClientRect.top < 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    const { current } = placeholderRef;

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0,
    });

    if (current && stickyRef.current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [placeholderRef]);

  useEffect(() => {
    if (isSticky) {
      wrapperRef.current.style.minHeight = `${stickyRef.current.offsetHeight}px`;
    } else {
      wrapperRef.current.style.minHeight = `${0}px`;
    }
  }, [isSticky]);

  return (
    <>
      <Wrapper ref={wrapperRef}>
        <Placeholder ref={placeholderRef} />
      </Wrapper>
      <StickyWrapper
        ref={stickyRef}
        className={isSticky ? "sticky" : ""}
        offsetFromBanner={offsetFromBanner}
      >
        {children}
      </StickyWrapper>
    </>
  );
};

export default StickyBar;
