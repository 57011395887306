import styled from "styled-components";
import Cart from "../../store/cart/model";
import CartProduct from "../../store/cart-product/model";
import LedgerItems from "./LedgerItems";
import LedgerReceipt from "./LedgerReceipt";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Icons } from "../../utils/react-svg";
import LedgerCouponGiftCards from "./LedgerCouponGiftCards";

const CartLedgerWrapper = styled.div`
  background-color: var(--white);
  padding: var(--spacing-1) var(--spacing-1_5) var(--spacing-1_5);

  @media (max-width: 932px) {
    border-top: 1px solid var(--cool-40);
    margin-left: calc(var(--grid-margin-xs) * -1);
    margin-right: calc(var(--grid-margin-xs) * -1);
  }

  @media (min-width: 933px) {
    padding-top: var(--spacing-1_5);
  }
`;

const Title = styled.h2`
  margin-bottom: var(--spacing-1_5);
  display: flex;
  justify-content: space-between;

  span.show,
  span.hide {
    transition: transform 0.3s;
  }

  span.show {
    transform: rotateX(180deg);
  }
`;
interface CartLedgerProps {
  activeCart: Cart;
  activeCartProducts: CartProduct[];
  isProcessing: boolean;
  activeCoupon?: any;
  appliedPromotion?: any;
}

const CartLedger = ({
  activeCart,
  activeCartProducts,
  activeCoupon,
  appliedPromotion,
  isProcessing,
}: CartLedgerProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const [canCollapse, setCanCollapse] = useState(false);

  const handleCollapse = () => {
    if (!canCollapse) return;

    setCollapsed(!collapsed);
  };

  const checkWindowSize = useCallback(() => {
    const currentWidth = window.innerWidth;
    if (currentWidth <= 932) {
      setCanCollapse(true);
      setCollapsed(true);
    } else {
      setCanCollapse(false);
      setCollapsed(false);
    }
  }, []);

  useEffect(() => {
    let previousWidth = window.innerWidth;

    const debouncedCheckWindowSize = debounce(() => {
      const currentWidth = window.innerWidth;
      // Only run the resize logic if the width has actually changed
      if (currentWidth !== previousWidth) {
        checkWindowSize();
        previousWidth = currentWidth;
      }
    }, 200);

    checkWindowSize();
    if (window.innerWidth <= 932) {
      setCollapsed(true);
    }

    window.addEventListener("resize", debouncedCheckWindowSize);

    return () => {
      window.removeEventListener("resize", debouncedCheckWindowSize);
    };
  }, [checkWindowSize]);

  return (
    <>
      <CartLedgerWrapper>
        <Title
          className="typography-eyebrow1"
          onClick={canCollapse ? handleCollapse : undefined}
          role={canCollapse ? "button" : undefined}
          aria-expanded={canCollapse ? !collapsed : undefined}
          tabIndex={canCollapse ? 0 : -1}
          aria-hidden={!canCollapse}
          onKeyDown={(e) => {
            if (canCollapse && (e.key === "Enter" || e.key === " ")) {
              handleCollapse();
            }
          }}
          style={{ cursor: canCollapse ? "pointer" : "default" }}
        >
          <span>Order Summary</span>
          {canCollapse && (
            <span className={collapsed ? "show" : "hide"}>
              <Icons.CaretDown />
            </span>
          )}
        </Title>
        <LedgerItems
          activeCartProducts={activeCartProducts}
          collapsed={collapsed}
        />
        {!collapsed && <LedgerCouponGiftCards activeCart={activeCart} />}
        <LedgerReceipt
          activeCart={activeCart}
          activeCartProducts={activeCartProducts}
          activeCoupon={activeCoupon}
          appliedPromotion={appliedPromotion}
          isProcessing={isProcessing}
          collapsed={collapsed}
        />
      </CartLedgerWrapper>
    </>
  );
};

export default CartLedger;
