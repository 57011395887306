import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { GridContainer } from "@ritual/essentials-for-react";

// Redux
import cartSelectors from "../../store/cart/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";
import couponSelectors from "../../store/coupon/selectors";
import promotionSelectors from "../../store/promotion/selectors";

import { fetchCart } from "../../store/cart/actions";

// Components
import StripeElementsCheckout from "../../components/checkout/StripeElementsCheckout";
import ErrorBanner from "../../components/checkout/ErrorBanner";
import SavingsBanner from "../../components/checkout/SavingsBanner";
import CartLedger from "../../components/checkout/CartLedger";
import GridRow from "../../components/grid/GridRow";
import GridColumn from "../../components/grid/GridColumn";
import SimpleNav from "../../components/global/navigation/SimpleNav";
import SubscriptionBenefits from "../../components/product/hero/SubscriptionBenefits";
import SimpleFooter from "../../components/global/navigation/SimpleFooter";
import useVariation from "../../hooks/useVariation";

// Util functions and types
import {
  StripeFormattedAddress,
  AddressVerificationOutput,
} from "../../utils/types/validateAddressTypes";
import { patchCart } from "../../components/checkout/utils";
import {
  trackCartUpdated,
  trackCheckoutStarted,
} from "../../utils/tracking/cart";
import Cart from "../../store/cart/model";
import metrics from "../../utils/metrics";
import { navigate } from "../../services/navigation";

const PageWrapper = styled.div`
  min-height: 100svh;
  display: flex;
  flex-direction: column;
`;

const PageBackground = styled.div`
  background-color: var(--warm-20);
  flex: 1 1 100%;

  @media (min-width: 933px) {
    padding: var(--spacing-3) 0;
  }
`;

const LeftColumnSpacing = styled.div`
  margin-bottom: var(--spacing-3);

  @media (min-width: 933px) {
    max-width: 660px;
    margin-left: auto;
  }
`;

const RightColumnSpacing = styled.div`
  @media (min-width: 933px) {
    max-width: 432px;
  }
`;

const ValuePropsWrapperDesktop = styled.div`
  padding: 0 var(--spacing-1_5) var(--spacing-1_5);

  @media (min-width: 750px) {
    padding-top: var(--spacing-1_5);
  }

  & > * {
    margin-top: var(--spacing-1_5);
  }
`;

const ValuePropsWrapperMobile = styled.div`
  @media (min-width: 933px) {
    display: none;
  }

  & > * {
    margin-top: var(--spacing-3);
    margin-bottom: var(--spacing-2);
  }
`;

const CheckoutPage = () => {
  const [addressValidationResult, setAddressValidationResult] =
    useState<AddressVerificationOutput | null>(null);

  const [name, setName] = useState<string | null>(null);
  const [address, setAddress] = useState<StripeFormattedAddress | null>(null);
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);
  const [lastPaymentError, setLastPaymentError] = useState<any>(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [tryingPurchase, setTryingPurchase] = useState(false);

  const useGoogleMapsAddressValidation = useVariation(
    "use-google-maps-address-validation",
  );

  const dispatch = useDispatch();

  const activeCart = useSelector(cartSelectors.activeCart);
  const activeCartProducts = useSelector(
    cartProductSelectors.sortedActiveCartProducts,
  );
  const hasEpre = useSelector(cartProductSelectors.activeCartContainsEpre);
  const isProcessing = useSelector(cartSelectors.isProcessing);
  const activeCoupon = useSelector(couponSelectors.activeCoupon);
  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);

  const recurringBillingDetails = activeCart?.recurringBillingDetails;

  const canCheckout =
    !isProcessing && activeCart && activeCartProducts && !tryingPurchase;

  useEffect(() => {
    setHasLoaded(true);

    trackCheckoutStarted();
  }, []);

  useEffect(() => {
    if (
      activeCart &&
      (activeCart.subtotal === 0 || !activeCart.subtotal) &&
      activeCartProducts.length < 1 &&
      !isProcessing
    ) {
      navigate("/");
    }
  }, [activeCart, activeCartProducts, isProcessing]);

  const handleAddressChange = async (
    address: StripeFormattedAddress,
    activeCart: Cart,
  ) => {
    if (
      !address?.line1 ||
      !address?.city ||
      !address?.state ||
      !address?.postal_code ||
      !address?.country
    ) {
      return;
    }

    setLastPaymentError(null);
    setShowErrorBanner(false);

    const payload: any = activeCart.serialize({
      shippingAddress: { ...address },
    });

    try {
      const response = await patchCart(payload.data.attributes, activeCart);
      await dispatch(fetchCart());
      setDisableSubmit(false);

      sessionStorage.setItem(
        "rit-purchased_cart",
        JSON.stringify(response.data.attributes),
      );

      const appliedPromotionId = response.data.relationships.promotion.data?.id;
      const appliedPromotion =
        appliedPromotionId &&
        response.included.find((i: any) => i.id === appliedPromotionId);

      sessionStorage.setItem(
        "rit-applied_promotion",
        JSON.stringify(appliedPromotion),
      );

      metrics.track("Shipping Info Entered");
      trackCartUpdated();
    } catch (error: any) {
      if (error.errors?.[0]?.source === "Taxjar") {
        setLastPaymentError({
          decline_code: "zip_code_not_matching_state",
        });
        setShowErrorBanner(true);
        setDisableSubmit(true);
      }
    }
  };

  const savings =
    (activeCart?.discountAmount || 0) +
    (activeCart?.productOfferDiscountAmount || 0);

  return (
    <PageWrapper>
      <SimpleNav />
      {savings > 0 && <SavingsBanner savingsAmount={savings} />}
      {showErrorBanner && <ErrorBanner lastPaymentError={lastPaymentError} />}

      <PageBackground>
        <GridContainer>
          <GridRow>
            <GridColumn
              xs={{ size: 6 }}
              s={{ size: 12 }}
              m={{ size: 12, hide: true }}
            >
              {hasLoaded && activeCart && activeCartProducts && (
                <CartLedger
                  activeCart={activeCart}
                  activeCartProducts={activeCartProducts}
                  activeCoupon={activeCoupon}
                  appliedPromotion={appliedPromotion}
                  isProcessing={isProcessing}
                />
              )}
            </GridColumn>

            <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 7 }}>
              <LeftColumnSpacing>
                {hasLoaded && (
                  <StripeElementsCheckout
                    address={address}
                    name={name}
                    setName={setName}
                    addressValidationResult={addressValidationResult}
                    setAddressValidationResult={setAddressValidationResult}
                    useGoogleMapsAddressValidation={
                      useGoogleMapsAddressValidation
                    }
                    setShowErrorBanner={setShowErrorBanner}
                    setLastPaymentError={setLastPaymentError}
                    activeCart={activeCart}
                    activeCartProducts={activeCartProducts}
                    handleAddressChange={handleAddressChange}
                    setAddress={setAddress}
                    disableSubmit={disableSubmit}
                    recurringBillingDetails={recurringBillingDetails}
                    canCheckout={canCheckout}
                    tryingPurchase={tryingPurchase}
                    setTryingPurchase={setTryingPurchase}
                  />
                )}
                <ValuePropsWrapperMobile>
                  <SubscriptionBenefits
                    product={activeCartProducts[0]}
                    showFsaHsa={hasEpre}
                  />
                </ValuePropsWrapperMobile>
              </LeftColumnSpacing>
            </GridColumn>

            <GridColumn
              xs={{ size: 6, hide: true }}
              s={{ size: 6, hide: true }}
              m={{ size: 5 }}
            >
              <RightColumnSpacing>
                {hasLoaded && activeCart && activeCartProducts && (
                  <>
                    <CartLedger
                      activeCart={activeCart}
                      activeCartProducts={activeCartProducts}
                      activeCoupon={activeCoupon}
                      appliedPromotion={appliedPromotion}
                      isProcessing={isProcessing}
                    />
                    <ValuePropsWrapperDesktop>
                      <SubscriptionBenefits
                        product={activeCartProducts[0]}
                        showFsaHsa={hasEpre}
                      />
                    </ValuePropsWrapperDesktop>
                  </>
                )}
              </RightColumnSpacing>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </PageBackground>
      <SimpleFooter />
    </PageWrapper>
  );
};

export default CheckoutPage;
