import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  addCouponToCart,
  addGiftCardToCart,
  removeGiftCardFromCart,
} from "../../store/cart/actions";
import styled from "styled-components";
import { removeInput } from "../../store/apply-code/actions";
import Cart from "../../store/cart/model";
import FloatingInput from "../global/FloatingInput";
import { Icons } from "../../utils/react-svg";
import { trackCartUpdated } from "../../utils/tracking/cart";

const InputField = styled.div`
  width: 100%;
  margin-bottom: var(--spacing-1_5);

  p.error-message {
    color: var(--error-red);
    font-size: var(--spacing-0_75);
    font-weight: 500;
    line-height: var(--spacing-1_25);
    margin-top: var(--spacing-0_5);
  }
`;

const ChipContainer = styled.div`
  display: flex;
  gap: var(--spacing-0_5);
`;

const Chip = styled.div.attrs({
  className: "typography-caption",
})`
  display: flex;
  padding: var(--spacing-0_25) var(--spacing-0_5);
  align-items: center;
  border-radius: var(--spacing-0_25);
  background: var(--indigo-blue);
  color: var(--white);
  margin-top: var(--spacing-1);

  button {
    background: none;
    border: none;
    color: var(--white);
    padding: 0;
    cursor: pointer;
    margin-left: var(--spacing-0_5);
    display: flex;
    align-items: center;
  }
`;

interface ErrorMessage {
  status: number;
  detail?: string;
  error_class?: string;
}

interface ErrorResponse {
  status: number;
  errors: ErrorMessage[];
}

const getErrorMessage = (error: ErrorResponse) => {
  const errorResponse = error?.errors?.[0];

  if (!errorResponse) return "Bummer, that’s an invalid code.";

  switch (errorResponse.error_class) {
    case "Exceptions::CannotCombineCouponWithProductOfferError":
      return "This code is not valid for the items in your cart.";
    case "Exceptions::InvalidCouponCodeError":
    default:
      return "Bummer, that’s an invalid code.";
  }
};

interface ActiveCartRequiredProps
  extends Pick<Cart, "giftCardCodes" | "discountCode"> {
  giftCardCodes: string[];
  discountCode: string;
}

interface LedgerCouponGiftCardsProps {
  activeCart: ActiveCartRequiredProps;
}

const LedgerCouponGiftCards = ({ activeCart }: LedgerCouponGiftCardsProps) => {
  const [couponOrGiftCard, setCouponOrGiftCard] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const resetInput = () => {
    setCouponOrGiftCard("");
  };

  const handleAddCode = async (action: any) => {
    setError("");
    try {
      await dispatch(action(couponOrGiftCard));

      trackCartUpdated();
      resetInput();
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleAddCouponOrGiftCard = () => {
    if (!couponOrGiftCard) return;

    const codeType = couponOrGiftCard.startsWith("GC") ? "giftCard" : "coupon";
    const action =
      codeType === "giftCard" ? addGiftCardToCart : addCouponToCart;

    handleAddCode(action);
  };

  const handleError = (error: any) => {
    const errorMessage = getErrorMessage(error);
    setError(errorMessage);
  };

  const handleRemoveGiftCard = async (code: string) => {
    await dispatch(removeGiftCardFromCart(code));
    trackCartUpdated();
  };

  const handleRemoveCoupon = async () => {
    await dispatch(removeInput());
    trackCartUpdated();
  };

  return (
    <InputField>
      <FloatingInput
        placeholder="Discount code or gift card"
        value={couponOrGiftCard}
        onChange={({ target: { value } }) => {
          const newValue = value?.toUpperCase() || "";
          setCouponOrGiftCard(newValue);
          if (error) setError("");
        }}
        onClick={handleAddCouponOrGiftCard}
        onBlur={() => {
          if (!couponOrGiftCard) {
            setCouponOrGiftCard("");
          }
        }}
        error={error}
        buttonText="Apply"
        minLength={0}
        maxLength={20}
      />
      {error && <p className="error-message">{error}</p>}
      <ChipContainer>
        {activeCart?.giftCardCodes?.map((code: string) => (
          <Chip key={code}>
            {code}{" "}
            <button
              aria-label={`Remove ${code} gift card`}
              onClick={() => handleRemoveGiftCard(code)}
            >
              <Icons.ChipClose />
            </button>
          </Chip>
        ))}
        {activeCart?.discountCode && (
          <Chip key={activeCart?.discountCode}>
            {activeCart.discountCode}
            <button
              aria-label={`Remove ${activeCart.discountCode} coupon`}
              onClick={handleRemoveCoupon}
            >
              <Icons.ChipClose />
            </button>
          </Chip>
        )}
      </ChipContainer>
    </InputField>
  );
};

export default LedgerCouponGiftCards;
