import styled from "styled-components";
import { Color } from "../../utils/styleDesignSystem";

import { Icons } from "../../utils/react-svg";
import { responsive } from "../../utils/style";
import StickyBar from "../../components/global/StickyBar";

const Container = styled.div`
  display: flex;
  padding: var(--Between-Components-Only-spacing-0_75, 12px);
  align-items: flex-start;
  gap: var(--Between-Components-Only-spacing-0_75, 12px);
  align-self: stretch;
  background-color: ${Color.errorRed};
  flex-direction: row;
  justify-content: flex-start;
  z-index: 2;

  ${responsive.sm`
      justify-content: center;
      align-items: center;
  `};
`;

const IconContainer = styled.div`
  height: 100%;
  width: 16px;

  ${responsive.sm`
    display: flex;
    align-items: flex-start;
    align-items: center;
  `};
`;

const Text = styled.div`
  color: ${Color.white};
`;

interface ErrorBannerProps {
  lastPaymentError?: {
    decline_code?: string;
  };
}

const getErrorMessage = (code?: string) => {
  switch (code) {
    case "insufficient_funds":
      return "Your payment was unsuccessful due to insufficient funds. Please check your account balance or use a different payment method.";
    case "expired_card":
      return "Your payment was unsuccessful because your card has expired. Please use a different card or update your card details.";
    case "incorrect_cvc":
      return "Your payment was unsuccessful due to an incorrect CVC code. Please double-check the CVC code and try again.";
    case "card_declined":
      return "Your payment was unsuccessful because your card was declined. Please contact your bank or use a different payment method.";
    case "processing_error":
      return "Your payment was unsuccessful due to a processing error. Please try again later or use a different payment method.";
    case "incorrect_number":
      return "Your payment was unsuccessful due to an incorrect card number. Please double-check the card number and try again.";
    case "authentication_required":
      return "Your payment was unsuccessful because additional authentication is required. Please complete the authentication process and try again.";
    case "do_not_honor":
      return "Your payment was unsuccessful because your bank did not honor the transaction. Please contact your bank or use a different payment method.";
    case "lost_card":
      return "Your payment was unsuccessful because the card has been reported lost. Please use a different card.";
    case "stolen_card":
      return "Your payment was unsuccessful because the card has been reported stolen. Please use a different card.";
    case "generic_decline":
      return "Your payment was unsuccessful because your card was declined. Please contact your bank or use a different payment method.";
    case "incorrect_pin":
      return "Your payment was unsuccessful due to an incorrect PIN. Please double-check your PIN and try again.";
    case "issuer_not_available":
      return "Your payment was unsuccessful because the card issuer is not available. Please try again later or use a different payment method.";
    case "new_account_information_available":
      return "Your payment was unsuccessful because new account information is available. Please update your card details and try again.";
    case "restricted_card":
      return "Your payment was unsuccessful because the card is restricted. Please use a different card.";
    case "revocation_of_authorization":
      return "Your payment was unsuccessful because the authorization was revoked. Please contact your bank or use a different payment method.";
    case "revocation_of_all_authorizations":
      return "Your payment was unsuccessful because all authorizations were revoked. Please contact your bank or use a different payment method.";
    case "security_violation":
      return "Your payment was unsuccessful due to a security violation. Please contact your bank or use a different payment method.";
    case "service_not_allowed":
      return "Your payment was unsuccessful because the service is not allowed. Please contact your bank or use a different payment method.";
    case "stop_payment_order":
      return "Your payment was unsuccessful because a stop payment order was issued. Please contact your bank or use a different payment method.";
    case "testmode_decline":
      return "Your payment was unsuccessful because it was declined in test mode. Please use a real card or contact support.";
    case "transaction_not_allowed":
      return "Your payment was unsuccessful because the transaction is not allowed. Please contact your bank or use a different payment method.";
    case "zip_code_not_matching_state":
      return "There was a problem updating your address. Please check it and try again.";
    default:
      return "Your purchase was unsuccessful. Please check your payment method details and try again.";
  }
};

const ErrorBanner: React.FC<ErrorBannerProps> = ({ lastPaymentError }) => {
  return (
    <StickyBar>
      <Container>
        <IconContainer>
          <Icons.AlertWarning />
        </IconContainer>
        <Text className="typography-body3 font-circular">
          {lastPaymentError?.decline_code
            ? getErrorMessage(lastPaymentError?.decline_code)
            : "An unexpected error occurred while submitting your order. Please try again."}
        </Text>
      </Container>
    </StickyBar>
  );
};

export default ErrorBanner;
