import styled from "styled-components";
import CopyrightText from "../footer/CopyrightText";
import RitualLogo from "../RitualLogo";
import GridContainer from "../../grid/GridContainer";
import GridRow from "../../grid/GridRow";
import GridColumn from "../../grid/GridColumn";

const SimpleFooterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-2) 0 var(--spacing-3);

  & > *:first-child {
    width: 52px;
    flex: 0 0 52px;
    margin-right: ;
  }

  & > *:last-child {
    text-align: right;

    & > * {
      margin-right: 0;
      margin-left: var(--spacing-1);
      text-align: right;

      button {
        text-align: right;
      }
    }
  }

  &::before {
    content: "";
    display: block;
    border-top: 1px solid var(--indigo-blue);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;

const SimpleFooter = () => {
  return (
    <GridContainer>
      <GridRow>
        <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
          <SimpleFooterWrapper>
            <RitualLogo />
            <CopyrightText />
          </SimpleFooterWrapper>
        </GridColumn>
      </GridRow>
    </GridContainer>
  );
};

export default SimpleFooter;
