import Modal from "../MagicModal";
import styled, { css } from "styled-components";

import {
  AddressVerificationOutput,
  StripeFormattedAddress,
} from "../../utils/types/validateAddressTypes";

import { mapPostalAddressToStripeFormat } from "../../utils/validateAddress";

import SuggestedAddress from "./SuggestedAddress";
import Cart from "../../store/cart/model";
import { useEffect, useRef } from "react";

interface AddressSuggestionModalProps {
  suggestionModalOpen: boolean;
  onRequestClose: () => void;
  setAddress: React.Dispatch<
    React.SetStateAction<StripeFormattedAddress | null>
  >;
  addressValidationResult: AddressVerificationOutput | null;
  setAcceptedAddressSelection: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAddressSuggestionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSeenAddressSuggestionModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddressChange: (
    addressInput: StripeFormattedAddress,
    cart: Cart,
  ) => Promise<void>;
  activeCart: Cart;
}

const Suggestion = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--between-components-modules-spacing-1, 16px);
  gap: var(--between-components-modules-spacing-1, 16px);
  align-items: flex-start;
  align-self: stretch;
`;

const Headers = styled.div``;

const Header = styled.h3`
  margin-bottom: var(--spacing-0_25, 4px);
`;

const HelpText = styled.p`
  color: var(--Solid-Primary-IndigoBlue-60, #62719f);
  margin-bottom: 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: var(--Solid-Neutral-Cool-40, #eaeef0);
`;

const Button = styled.button<{ $side: string }>`
  width: 50%;
  height: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border: none;
  font-weight: 450;
  font-size: 14px;
  transition: background-color 200ms ease-out;

  border-right: ${(props) => (props.$side === "left" ? "1px solid" : "none")};
  border-color: var(--Solid-Neutral-Cool-40, #eaeef0);
  color: var(--Solid-Primary-IndigoBlue-100, #142b6f);
  color: ${(props) =>
    props.$side === "left" && `var(--Solid-Semantic-SuccessGreen, #4C840D)`};

  &:hover {
    background-color: var(--Solid-Neutral-Cool-20, #f5f7f8);
  }
`;

const ModalStyle = css`
  padding: 0 !important;
  border-radius: var(--spacing-0_25, 4px);
  min-width: 327px;
`;

const ModalCloseButtonStyle = css`
  display: none;
`;

const AddressSuggestionModal = ({
  suggestionModalOpen,
  onRequestClose,
  addressValidationResult,
  setAddress,
  setAcceptedAddressSelection,
  setShowAddressSuggestionModal,
  setSeenAddressSuggestionModal,
  handleAddressChange,
  activeCart,
}: AddressSuggestionModalProps) => {
  const postalAddress = addressValidationResult?.postalAddress;
  const acceptButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (suggestionModalOpen && acceptButtonRef.current) {
      acceptButtonRef.current.focus();
    }
  }, [suggestionModalOpen]);

  if (!postalAddress) return null; //update logic for non us addresses

  const acceptAddressSuggestion = () => {
    const acceptedAddress = mapPostalAddressToStripeFormat(postalAddress);
    if (!acceptedAddress) return;

    handleAddressChange({ ...acceptedAddress, validated: true }, activeCart);
    setAddress(acceptedAddress);
    setAcceptedAddressSelection(true);
    setSeenAddressSuggestionModal(true);
    setShowAddressSuggestionModal(false);
  };

  return (
    <Modal
      isOpen={suggestionModalOpen}
      onRequestClose={onRequestClose}
      contentStyle={ModalStyle}
      modalCloseButtonStyle={ModalCloseButtonStyle}
      aria-labelledby="address-suggestion-modal-title"
      aria-describedby="address-suggestion-modal-description"
    >
      <Suggestion>
        <Headers>
          <Header
            id="address-suggestion-modal-title"
            className="typography-label1 font-circular"
          >
            Verify your address
          </Header>
          <HelpText
            id="address-suggestion-modal-description"
            className="typography-body3 font-circular"
          >
            We found this address for the one you entered.
          </HelpText>
        </Headers>
        <SuggestedAddress postalAddress={postalAddress} />

        <HelpText className="typography-body3 font-circular">
          Accept this or edit your address.
        </HelpText>
      </Suggestion>
      <Buttons>
        <Button
          onClick={acceptAddressSuggestion}
          $side="left"
          ref={acceptButtonRef}
          id="address-suggestion-modal-accept"
        >
          Accept & Continue
        </Button>
        <Button
          onClick={onRequestClose}
          $side="right"
          id="address-suggestion-modal-edit"
        >
          Go Back & Edit
        </Button>
      </Buttons>
    </Modal>
  );
};

export default AddressSuggestionModal;
