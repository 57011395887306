import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import styled, { keyframes } from "styled-components";

import cartProductSelectors from "../../store/cart-product/selectors";
import { navigate } from "../../services/navigation";
import Cart, { RecurringBillingDetails } from "../../store/cart/model";
import CartProduct from "../../store/cart-product/model";
import { Icons } from "../../utils/react-svg";

import intl from "../../services/intl";
import locales from "../../../config/locales";

import {
  AddressElement,
  PaymentElement,
  LinkAuthenticationElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeAddressElementOptions,
  StripeAddressElementChangeEvent,
} from "@stripe/stripe-js";
import {
  AddressVerificationOutput,
  StripeFormattedAddress,
  IPostalAddress,
} from "../../utils/types/validateAddressTypes";
import {
  mapPostalAddressToStripeFormat,
  validateAddress,
} from "../../utils/validateAddress";

import { Color } from "../../utils/styleDesignSystem";
import metrics from "../../utils/metrics";
import RitualButton from "../global/RitualButton";

import {
  tryApiPurchase,
  tryElementsSubmit,
  tryCreateConfirmationToken,
  tryHandleNextAction,
  stripeElementsConfig,
} from "./utils";

import AddressSuggestionModal from "./AddressSuggestionModal";
import AddressSuggestionInline from "./AddressSuggestionInline";
import CheckoutTerms from "./CheckoutTerms";

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 4px;
  padding-right: 4px;

  @media (min-width: 933px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--spacing-1);

    h2,
    p {
      margin-bottom: 0;
    }
  }
`;

const Title = styled.h2`
  &.spacing-bottom {
    margin-bottom: var(--spacing-1);
  }

  &.spacing-top {
    margin-top: var(--spacing-3);
  }
`;

const SignInPrompt = styled.p`
  font-style: italic;
`;

const SignInLink = styled.a`
  text-decoration: underline;
  font-style: normal;
`;

const MarketingConsentContainer = styled.label`
  display: flex;
  margin-top: var(--Between-Components-Only-spacing-1, 16px);
  align-items: flex-start;
  padding-left: 4px;
  padding-right: 4px;
`;

const MarketingConsentText = styled.p`
  font-family: CircularXX;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  margin-bottom: 0;
`;

const MarketingOptInCheckbox = styled.input`
  margin-right: var(--Between-Components-Only-spacing-1, 16px);
  accent-color: ${Color.indigoBlue};
  margin-top: 3px;
`;

const UnsubscribeText = styled.span`
  font-style: italic;
`;

const SafetyPromise = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SafeAndSecureContainer = styled.div`
  margin-top: var(--Between-Components-Only-spacing-0_75, 24px);
  margin-bottom: var(--Between-Components-Only-spacing-0_5, 8px);
`;

const SafeSecureText = styled.span`
  margin-left: var(--Between-Components-Only-spacing-0_25, 4px);
`;

const ellipsisDotFade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const EllipsisDot = styled.span`
  opacity: 0;
  animation: ${ellipsisDotFade} 1.5s linear infinite;
`;

const LoadingButtonText = styled.span`
  span:nth-child(1) {
    animation-delay: 0s;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
  }
`;

const GATSBY_GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY!;

const autocomplete = {
  mode: "google_maps_api",
  apiKey: GATSBY_GOOGLE_MAPS_API_KEY,
};

interface StripeElementsCheckoutProps {
  address: StripeFormattedAddress | null;
  setAddress: React.Dispatch<
    React.SetStateAction<StripeFormattedAddress | null>
  >;
  name: string | null;
  setName: React.Dispatch<React.SetStateAction<string | null>>;
  addressValidationResult: AddressVerificationOutput | null;
  setAddressValidationResult: React.Dispatch<
    React.SetStateAction<AddressVerificationOutput | null>
  >;
  useGoogleMapsAddressValidation: boolean;
  setShowErrorBanner: React.Dispatch<React.SetStateAction<boolean>>;
  setLastPaymentError: React.Dispatch<React.SetStateAction<any>>;
  handleAddressChange: (
    addressInput: StripeFormattedAddress,
    cart: Cart,
  ) => Promise<void>;
  activeCart: Cart;
  activeCartProducts: CartProduct[];
  disableSubmit: boolean;
  recurringBillingDetails: RecurringBillingDetails;
  canCheckout: boolean;
  tryingPurchase: boolean;
  setTryingPurchase: React.Dispatch<React.SetStateAction<boolean>>;
}

const StripeElementsCheckout = ({
  address,
  setAddress,
  name,
  setName,
  addressValidationResult,
  setAddressValidationResult,
  useGoogleMapsAddressValidation,
  setShowErrorBanner,
  setLastPaymentError,
  handleAddressChange,
  activeCart,
  activeCartProducts,
  disableSubmit,
  recurringBillingDetails,
  canCheckout,
  tryingPurchase,
  setTryingPurchase,
}: StripeElementsCheckoutProps) => {
  const [email, setEmail] = useState("");
  const [addressComplete, setAddressComplete] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

  const [showAddressComponent, setShowAddressComponent] = useState(true);
  const [acceptedAddressSelection, setAcceptedAddressSelection] =
    useState(false);
  const [showAddressSuggestionModal, setShowAddressSuggestionModal] =
    useState(false);
  const [seenAddressSuggestionModal, setSeenAddressSuggestionModal] =
    useState(false);
  const [marketingPreference, setMarketingPreference] = useState(true);
  const [nameInvalid, setNameInvalid] = useState(true);
  const [nameTooLong, setNameTooLong] = useState(true);

  const elements = useElements();
  const stripe = useStripe();

  const activeCartRecurringItems = useSelector(
    cartProductSelectors.activeCartRecurringItems,
  );
  const activeCartRecurringAny = activeCartRecurringItems.length > 0;

  const localeId = intl.locale;
  const locale = locales[localeId]!;

  const activeCountry = locale.country;

  let addressOptions = {
    mode: "shipping",
    allowedCountries: [activeCountry],
    ...(GATSBY_GOOGLE_MAPS_API_KEY && { autocomplete }),
  } as StripeAddressElementOptions;

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" or "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret =
      new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret",
      ) ||
      new URLSearchParams(window.location.search).get(
        "setup_intent_client_secret",
      );

    if (!clientSecret) {
      return;
    }

    const retrieveIntent = async () => {
      if (
        new URLSearchParams(window.location.search).has(
          "payment_intent_client_secret",
        )
      ) {
        return stripe.retrievePaymentIntent(clientSecret);
      } else {
        return stripe.retrieveSetupIntent(clientSecret);
      }
    };

    // Retrieve the PaymentIntent or SetupIntent
    retrieveIntent().then((result) => {
      const intent =
        "paymentIntent" in result
          ? result.paymentIntent
          : "setupIntent" in result
          ? result.setupIntent
          : null;

      if (!intent) {
        throw new Error("No intent found.");
      }

      // Inspect the intent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (intent.status) {
        case "succeeded":
        case "processing":
          navigate("/checkout/confirmation");
          break;

        default:
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
          );

          clearSessionStorage();
          if ("last_payment_error" in intent) {
            setLastPaymentError(intent.last_payment_error);
          }
          setShowErrorBanner(true);
          setTryingPurchase(false);
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  useEffect(() => {
    if (!elements || !activeCart) {
      return;
    }
    elements.update(stripeElementsConfig(activeCart, activeCartRecurringAny));
  }, [elements, activeCart, activeCartRecurringAny]);

  useEffect(() => {
    setMarketingPreference(locale["newsletter-checkbox-enabled"]);
  }, [locale]);

  useEffect(() => {
    setNameInvalid(
      Boolean(addressComplete && name && name.split(" ").length < 2),
    );
    setNameTooLong(Boolean(addressComplete && name && name.length > 35));
  }, [name, addressComplete]);

  useEffect(() => {
    setShowAddressComponent(false);
    setTimeout(() => setShowAddressComponent(true), 0);
  }, [acceptedAddressSelection]);

  const clearSessionStorage = () => {
    sessionStorage.removeItem("rit-cart_id");
    sessionStorage.removeItem("rit-marketing_preference");
    sessionStorage.removeItem("rit-purchased_cart");
    sessionStorage.removeItem("rit-purchased_cart_products");
    sessionStorage.removeItem("rit-order_number");
    sessionStorage.removeItem("rit-reset_password_token");
    sessionStorage.removeItem("rit-express_checkout_confirm_event");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setTryingPurchase(true);
    setLastPaymentError(null);
    setShowErrorBanner(false);
    setShowAddressSuggestionModal(false);

    sessionStorage.setItem(
      "rit-marketing_preference",
      marketingPreference.toString(),
    );

    metrics.track("Purchase Attempted");

    if (!addressComplete || !paymentComplete) {
      setTryingPurchase(false);
      return;
    }

    if (
      (addressValidationResult?.confirmAddress ||
        addressValidationResult?.fixAddress) &&
      !addressValidationResult?.perfectAddress &&
      !seenAddressSuggestionModal
    ) {
      setShowAddressSuggestionModal(true);
      setTryingPurchase(false);
      return;
    }

    await handleAddressChange(address!, activeCart);

    try {
      if (!stripe || !elements) {
        setTryingPurchase(false);
        return;
      }

      const { id: cartId } = activeCart;

      const { error: submitError } = await tryElementsSubmit(elements);
      if (submitError) {
        setTryingPurchase(false);
        return;
      }

      const { error: confirmationTokenError, confirmationToken } =
        await tryCreateConfirmationToken(stripe, elements);
      if (confirmationTokenError || !confirmationToken) {
        setTryingPurchase(false);
        return;
      }

      sessionStorage.setItem("rit-cart_id", cartId);
      sessionStorage.setItem(
        "rit-purchased_cart_products",
        JSON.stringify(activeCartProducts),
      );
      const { response: purchase, error } = await tryApiPurchase({
        confirmationToken: confirmationToken,
        cartId,
      });
      if (error || !purchase) {
        const paymentError = error?.errors?.[0];
        if (paymentError?.source === "Stripe") {
          setLastPaymentError(paymentError);
        }
        setShowErrorBanner(true);
        setTryingPurchase(false);
        return;
      }

      sessionStorage.setItem("rit-order_number", purchase.order_number);

      if (purchase?.reset_password_token) {
        sessionStorage.setItem(
          "rit-reset_password_token",
          purchase?.reset_password_token,
        );
      }

      let confirmEvent = {
        billingDetails:
          confirmationToken.payment_method_preview.billing_details,
        shippingAddress: confirmationToken.shipping,
        expressPaymentType: confirmationToken.payment_method_preview.type,
        confirmationToken: confirmationToken,
      };
      sessionStorage.setItem(
        "rit-express_checkout_confirm_event",
        JSON.stringify(confirmEvent),
      );

      sessionStorage.setItem("rit-checkout", "home");

      metrics.identify(purchase.user_id, { email, marketingPreference });

      if (purchase.requires_action && purchase.client_secret) {
        const { error: handleNextActionError } = await tryHandleNextAction(
          purchase.client_secret,
          stripe,
        );
        if (handleNextActionError) {
          setLastPaymentError(handleNextActionError);
          setShowErrorBanner(true);
          setTryingPurchase(false);
          return;
        }
      }

      navigate("/checkout/confirmation");
    } catch (e) {
      metrics.track("Purchase Failed", {
        reason: e,
      });
      clearSessionStorage();
      setShowErrorBanner(true);
      setTryingPurchase(false);
    }
  };

  const debouncedHandleAddressChangeCallback = useMemo(
    () =>
      debounce(async (address, cart) => {
        const addressValidationResult = await validateAddress(
          address,
          useGoogleMapsAddressValidation,
        );
        if (!addressValidationResult) return;
        setAddressValidationResult(addressValidationResult);
        let validated = addressValidationResult?.perfectAddress;
        await handleAddressChange({ ...address, validated }, cart);
      }, 500),
    // eslint-disable-next-line
    [],
  );

  const acceptAddressSuggestion = (
    postalAddress: IPostalAddress | null | undefined,
  ) => {
    if (!postalAddress) return;
    const acceptedAddress = mapPostalAddressToStripeFormat(postalAddress);
    if (!acceptedAddress) return;
    setAddress(acceptedAddress);
    handleAddressChange({ ...acceptedAddress, validated: true }, activeCart);
    setAcceptedAddressSelection(true);
    setTimeout(() => {
      setAcceptedAddressSelection(false);
    }, 0);
  };

  const onAddressUpdate = async (e: StripeAddressElementChangeEvent) => {
    setAddress(e.value.address);
    setName(e.value.name);
    setAddressComplete(e.complete);

    if (e.complete && !nameInvalid && !nameTooLong) {
      await debouncedHandleAddressChangeCallback(e.value.address, activeCart);
    }
  };

  const onPaymentChange = (e: any) => {
    const {
      complete,
      value: { type },
    } = e;
    setPaymentComplete(complete);

    if (complete) {
      metrics.track("Payment Info Entered", {
        type,
      });
    }
  };

  if (address) {
    addressOptions = {
      ...addressOptions,
      defaultValues: {
        name,
        address: { ...address, country: activeCountry },
      },
    };
  }

  const disableSubmitButton =
    !canCheckout ||
    !addressComplete ||
    !paymentComplete ||
    disableSubmit ||
    nameInvalid ||
    nameTooLong;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Heading>
          <Title className="typography-lead2">Your Details</Title>
          <SignInPrompt className="typography-label2 font-dutch">
            Already have a Ritual account?{" "}
            <SignInLink
              className="typography-label2 font-circular"
              href={process.env.GATSBY_CHECKOUT_URL}
            >
              Sign in
            </SignInLink>
          </SignInPrompt>
        </Heading>
        <LinkAuthenticationElement onChange={(e) => setEmail(e.value.email)} />
        <MarketingConsentContainer>
          <MarketingOptInCheckbox
            type="checkbox"
            checked={marketingPreference}
            onChange={(e) => setMarketingPreference(e.target.checked)}
          />
          <MarketingConsentText className="typography-label3 font-circular">
            I want to receive updates, tips, and other emails from Ritual{" "}
            <UnsubscribeText className="typography-label3 font-dutch">
              (you can unsubscribe any time)
            </UnsubscribeText>
          </MarketingConsentText>
        </MarketingConsentContainer>

        <Title className="typography-lead2 spacing-top spacing-bottom">
          Delivery
        </Title>
        {!showAddressComponent && <p>...</p>}
        {showAddressComponent && (
          <AddressElement options={addressOptions} onChange={onAddressUpdate} />
        )}

        {addressValidationResult && (
          <AddressSuggestionInline
            acceptAddressSuggestion={acceptAddressSuggestion}
            addressValidationResult={addressValidationResult}
            nameInvalid={nameInvalid}
            nameTooLong={nameTooLong}
          />
        )}
        <Title className="typography-lead2 spacing-top spacing-bottom">
          Payment
        </Title>
        <PaymentElement
          onChange={onPaymentChange}
          options={{
            layout: {
              type: "accordion",
              defaultCollapsed: false,
              radios: true,
              spacedAccordionItems: false,
            },
          }}
        />

        <CheckoutTerms recurringBillingDetails={recurringBillingDetails} />

        <RitualButton
          disabled={disableSubmitButton}
          aria-disabled={disableSubmitButton}
          aria-live={tryingPurchase ? "polite" : undefined}
          onClick={handleSubmit}
          className="fullwidth"
          isLink={false}
        >
          {tryingPurchase ? (
            <LoadingButtonText>
              Payment Processing<EllipsisDot>.</EllipsisDot>
              <EllipsisDot>.</EllipsisDot>
              <EllipsisDot>.</EllipsisDot>
            </LoadingButtonText>
          ) : (
            "Purchase"
          )}
        </RitualButton>
      </form>
      <SafetyPromise>
        <SafeAndSecureContainer>
          <Icons.LockShield />
          <SafeSecureText className="typography-caption">
            Safe and secure checkout
          </SafeSecureText>
        </SafeAndSecureContainer>
        <Icons.PoweredByStripe />
      </SafetyPromise>
      {showAddressSuggestionModal &&
        !seenAddressSuggestionModal &&
        !addressValidationResult?.perfectAddress && (
          <AddressSuggestionModal
            setAcceptedAddressSelection={setAcceptedAddressSelection}
            setAddress={setAddress}
            onRequestClose={() => {
              setShowAddressSuggestionModal(false);
              setSeenAddressSuggestionModal(true);
            }}
            setShowAddressSuggestionModal={setShowAddressSuggestionModal}
            suggestionModalOpen={showAddressSuggestionModal}
            addressValidationResult={addressValidationResult}
            setSeenAddressSuggestionModal={setSeenAddressSuggestionModal}
            handleAddressChange={handleAddressChange}
            activeCart={activeCart}
          />
        )}
    </>
  );
};

export default StripeElementsCheckout;
