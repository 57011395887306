import { useState } from "react";
import styled from "styled-components";
import { Icons } from "../../utils/react-svg";
import { Color } from "../../utils/styleDesignSystem";
import SuggestedAddress from "./SuggestedAddress";
import {
  AddressVerificationOutput,
  IPostalAddress,
} from "../../utils/types/validateAddressTypes";

const Container = styled.div`
  display: flex;
  padding: var(--Between-Components-Only-spacing-0_75, 12px);
  justify-content: center;
  align-items: flex-start;
  gap: var(--Between-Components-Only-spacing-0_75, 12px);
  align-self: stretch;
  background-color: ${Color.cool40};
  flex-direction: row;
  justify-content: flex-start;
  margin-top: var(--Between-Components-Only-spacing-1, 16px);
`;

const IconContainer = styled.div`
  width: 16px;
  height: 100%;
`;

const AddressInvalidText = styled.div`
  font-size: 14px;
  font-weight: 450;
  line-height: 26px;

  &.cursor-pointer {
    cursor: pointer;
  }

  p {
    margin: 0;
  }
`;

const AddressInvalidInline = ({
  addressValidationResult,
  acceptAddressSuggestion,
  nameInvalid,
  nameTooLong,
}: {
  addressValidationResult: AddressVerificationOutput;
  acceptAddressSuggestion: (address: IPostalAddress) => void;
  nameInvalid: boolean;
  nameTooLong: boolean;
}) => {
  const [display, setDisplay] = useState(true);
  if (!display) return null;

  const {
    missingApartmentNumber,
    postalAddress,
    perfectAddress,
    confirmAddress,
    fixAddress,
  } = addressValidationResult;

  const problems = [
    {
      value: nameInvalid,
      copy: "Please be sure to include your first and last name.",
    },
    {
      value: nameTooLong,
      copy: "Name must be 35 characters or less.",
    },
    {
      value: missingApartmentNumber,
      copy: "Your address might be missing an apartment or suite number. Please check above.",
    },
  ].filter((p) => p.value);

  const problem = problems.length > 0 ? problems[0] : null;
  const suggestAddress =
    !perfectAddress && (fixAddress || confirmAddress) && postalAddress;

  if (!suggestAddress && !problem) return null;

  const handleSelection = (address: IPostalAddress) => {
    setDisplay(false);
    acceptAddressSuggestion(address);
  };

  return (
    <Container role="alert">
      <IconContainer>
        <Icons.Info />
      </IconContainer>

      {suggestAddress && (
        <AddressInvalidText
          className="typography-body3 font-circular cursor-pointer"
          onClick={() => {
            handleSelection(postalAddress);
          }}
          data-testid="address-suggestion-inline"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleSelection(postalAddress);
            }
          }}
        >
          <p>Did you mean this address?</p>
          <SuggestedAddress postalAddress={postalAddress!} underlineAddress />
        </AddressInvalidText>
      )}

      {!suggestAddress && problem && (
        <AddressInvalidText className="typography-body3 font-circular">
          <p>{problem.copy}</p>
        </AddressInvalidText>
      )}
    </Container>
  );
};

export default AddressInvalidInline;
