// cSpell:words subpremise

import axios, { AxiosResponse } from "axios";
import {
  StripeFormattedAddress,
  AddressValidationResponse,
  AddressVerificationOutput,
  IPostalAddress,
} from "./types/validateAddressTypes";

const GATSBY_GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY || "";

export const VALIDATE_ADDRESS_URL =
  "https://addressvalidation.googleapis.com/v1:validateAddress";

/**
 * Private function for pulling out the relevant parts of a Google Address Validation response
 */
const addressValidationVerdict = (
  addressValidation: AddressValidationResponse,
): AddressVerificationOutput | null => {
  const addressComplete = Boolean(
    addressValidation.result?.verdict?.addressComplete,
  );
  const postalAddress = addressValidation.result?.address?.postalAddress;
  const uspsData = addressValidation.result?.uspsData!;
  const dpvConfirmation = uspsData?.dpvConfirmation;
  const hasUnconfirmedComponents = Boolean(
    addressValidation.result?.verdict?.hasUnconfirmedComponents,
  );
  const hasReplacedComponents = Boolean(
    addressValidation.result?.verdict?.hasReplacedComponents,
  );
  const hasInferredComponents = Boolean(
    addressValidation.result?.verdict?.hasInferredComponents,
  );
  const inferredComponents =
    addressValidation.result?.address?.addressComponents?.filter(
      (component) => component.inferred,
    );
  const spellCorrectedComponents =
    addressValidation.result?.address?.addressComponents?.some(
      (component) => component.spellCorrected,
    );
  const validationGranularity =
    addressValidation.result?.verdict?.validationGranularity;

  const firstInferredComponent = inferredComponents?.[0];
  const onlyInferredComponentPostalCodeSuffix =
    hasInferredComponents &&
    inferredComponents?.length === 1 &&
    firstInferredComponent?.componentType === "postal_code_suffix";

  // The address is considered perfect if it's only missing the postal code suffix and everything else is complete
  const perfectAddress =
    addressComplete &&
    !hasReplacedComponents &&
    (!hasInferredComponents || onlyInferredComponentPostalCodeSuffix) &&
    !spellCorrectedComponents &&
    !hasUnconfirmedComponents;

  // Confirm the address if it's not perfect and the address validation granularity is better than ROUTE
  const confirmAddress =
    addressComplete &&
    (!hasReplacedComponents ||
      !hasInferredComponents ||
      onlyInferredComponentPostalCodeSuffix ||
      spellCorrectedComponents) &&
    validationGranularity !== "OTHER";

  // Fix the address if it's not close to perfect and the address validation granularity is OTHER
  const fixAddress = !addressComplete && validationGranularity === "OTHER";

  const missingApartmentNumber = Boolean(
    dpvConfirmation && dpvConfirmation === "D",
  );

  return {
    perfectAddress,
    confirmAddress,
    fixAddress,
    postalAddress,
    missingApartmentNumber,
  };
};

export const line1Valid = (line1: string, country: string) => {
  if (line1.length > 44 || line1.length < 4) return false;
  if (/@/.test(line1)) return false;

  const isPoBox = /box/i.test(line1);
  const hasLetter = /[A-Za-z]/.test(line1);
  const requiresNumber = country !== "GB";
  const hasNumber = /\d/.test(line1);
  const satisfiesNumberRequirement = !requiresNumber || hasNumber;

  return (satisfiesNumberRequirement && hasLetter) || isPoBox;
};

export const basicModelValidations = (address: StripeFormattedAddress) => {
  const {
    country,
    line1,
    line2,
    city,
    state,
    postal_code: postalCode,
  } = address;

  const line1Ok = line1Valid(line1, country);
  const line2Ok = line2 ? line2.length <= 40 : true;
  const cityOk = city && city.length >= 2 && city.length <= 50;
  const stateOk =
    country === "GB" || (state && state?.length >= 2 && state?.length <= 50);
  const postalCodeOk = postalCode.length >= 5 && postalCode.length <= 10;
  const countryOk = ["US", "GB", "CA"].includes(country);

  return [line1Ok, line2Ok, cityOk, stateOk, postalCodeOk, countryOk].every(
    (el) => el,
  );
};

/**
 * Validates an address pulled from Stripe's Address Element, and validates with Google's address verification API
 * https://docs.stripe.com/elements/address-element
 * https://developers.google.com/maps/documentation/address-validation
 */
export const validateAddress = async (
  address: StripeFormattedAddress,
  useGoogleMapsAddressValidation: boolean,
): Promise<AddressVerificationOutput | null> => {
  if (!basicModelValidations(address)) {
    return { fixAddress: true };
  }

  if (!useGoogleMapsAddressValidation) return { fixAddress: false };

  const {
    country: countryInput,
    line1,
    line2,
    city,
    state,
    postal_code: postalCode,
  } = address;

  const country = countryInput === "GB" ? "UK" : countryInput;

  const response: AxiosResponse<AddressValidationResponse> = await axios.post(
    VALIDATE_ADDRESS_URL,
    {
      address: {
        regionCode: country,
        locality: city,
        addressLines: [line1, line2],
        administrativeArea: state,
        postalCode,
      },
    },
    {
      params: {
        key: GATSBY_GOOGLE_MAPS_API_KEY,
      },
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return addressValidationVerdict(response.data);
};

export const mapPostalAddressToStripeFormat = (
  postalAddress?: IPostalAddress,
): StripeFormattedAddress | void => {
  if (!postalAddress) return;

  const { addressLines, locality, administrativeArea, postalCode, regionCode } =
    postalAddress;
  if (!addressLines || !locality || !postalCode || !administrativeArea) return;
  if (!addressLines?.[0] || addressLines[0].length < 4) return;

  return {
    line1: addressLines[0]!,
    line2: addressLines[1],
    city: locality!,
    state: administrativeArea!,
    postal_code: postalCode!,
    country: regionCode!,
  };
};
